import React from 'react'
import './resources/Footer.css'
import facebookImage from './resources/webicon-facebook.png'

export const Footer: React.FunctionComponent = () => (
  <footer>
    <div className='footer-column left'>
      <h4 className='footer-heading'>Join the Symphony</h4>
      <div className='footer-rule'>
        <hr />
      </div>
      <p>
        Are you looking for an opportunity to develop your skills with fun and challenging music? We are
        always looking for talented musicians to join the orchestra.{' '}
        <a href='/audition'>Request an audition today!</a>
      </p>
    </div>
    <div className='footer-column right'>
      <h4 className='footer-heading'>Rehearsals</h4>
      <div className='footer-rule'>
        <hr />
      </div>
      <p>
        Mondays, 7-9 pm (Bring your own music stand)
        <br />@ Rockwell Charter High School 
        <br />
        <a rel='noreferrer' href='https://www.google.com/maps/place/Rockwell+Charter+High+School/@40.3794034,-111.9798496,17z/data=!3m1!4b1!4m6!3m5!1s0x874d7db86656ff8f:0xb63c36c8fe70e54a!8m2!3d40.3793993!4d-111.9772747!16s%2Fm%2F08nkhrf?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D' target='_blank'>3435 E Stonebridge Ln, Eagle Mountain, UT 84005</a>
      </p>
    </div>
    <div className='footer-column right'>
      <h4 className='footer-heading'>Contacts</h4>
      <div className='footer-rule'>
        <hr />
      </div>
      <a href='mailto:eaglemountainsymphony@gmail.com'>eaglemountainsymphony&#64;gmail.com</a>
      <br />
      <a
        className='social-image'
        href='https://www.facebook.com/EagleMountainSymphonyOrchestra/'
        target='_blank'
        rel='noreferrer'>
        <img src={facebookImage} alt='Visit us on Facebook' />
      </a>
    </div>
  </footer>
)
